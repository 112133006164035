export function createIdMap<T extends { id: string }>(objects: T[]) {
  return objects.reduce((acc, curr) => {
    return {...acc, [curr.id]: curr};
  }, {} as Record<string, T>);
}

export const roundAmount = (num: number) => roundToX(num, 2);

export const roundToX = (num = 0, decimals = 2) => Math.round(num * 10 ** decimals) / 10 ** decimals;

export const nonEmptyObjects = <T>(objs: T[]) => {
  return objs.filter(obj => nonEmptyObject(obj));
}

export const nonEmptyObject = (obj: any) => {
  return Object.entries(obj)
    .filter(([key, value]) => key !== 'id' && nonEmptyValue(value))
    .length > 0;
}

const nonEmptyValue = (value: any) => {
  return value !== null && value !== undefined && value !== '';
}