import React from "react";
import {ActionIcon, Badge, Flex, Text, Tooltip} from "@mantine/core";
import {IconFlipHorizontal, IconFlipVertical, IconRotate2, IconRotateClockwise2} from "@tabler/icons-react";
import {ImageAction} from "../../../../utils/http/apiClient";


interface ImageActionProps {
  toolTip: string,
  icon: React.ReactNode
  onClick?: () => void
}

const ImageActionButton = ({toolTip, icon, onClick}: ImageActionProps) => {
  return <Tooltip label={toolTip}>
    <ActionIcon variant="subtle" tabIndex={-1} onClick={onClick}>
      {icon}
    </ActionIcon>
  </Tooltip>;
}

interface PageControlProps {
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
  onImageAction?: (action: ImageAction) => void
}

export const PageControl = ({pageCount, currentPage, onPageChange, onImageAction}: PageControlProps) => {
  return <Flex direction="row" justify="right" align="center">

    <Text style={{paddingRight: '0.5rem'}}>Actions: </Text>
    <ImageActionButton toolTip={"Rotate image 90 degrees counter clockwise"}
                       icon={<IconRotate2 size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("ROTATE_90_CCW")}/>
    <ImageActionButton toolTip={"Rotate image 90 degrees clockwise"}
                       icon={<IconRotateClockwise2 size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("ROTATE_90_CW")}/>
    <ImageActionButton toolTip={"Flip image vertically"}
                       icon={<IconFlipHorizontal size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("FLIP_VERTICAL")}/>
    <ImageActionButton toolTip={"Flip image horizontally"}
                       icon={<IconFlipVertical size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("FLIP_HORIZONTAL")}/>

    <Text style={{paddingLeft: '1rem', paddingRight: '0.5rem'}}>Pages: </Text>
    {[...Array(pageCount)].map((_, idx) => (
      <Badge key={idx}
             onClick={() => onPageChange(idx)}
             size="md"
             variant={idx === currentPage ? 'filled' : 'default'}
             style={{cursor: 'pointer'}}
      >
        {idx + 1}
      </Badge>
    ))}
  </Flex>;
}