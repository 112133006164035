import {ActionIcon, Space, Stack, Table} from "@mantine/core";
import {
  IconArrowForwardUpDouble,
  IconColumns3,
  IconDotsVertical,
  IconLink,
  IconTrashX,
  IconUnlink
} from "@tabler/icons-react";
import {StatusBadge} from "../../../../common/StatusBadge";
import React, {RefObject, useRef} from "react";
import {ApiExtraction} from "../../../../utils/http/apiClient";
import {DocumentLink} from "../../../../common/DocumentLink";
import {IncomingInvoiceModal} from "../incoming/invoices/IncomingInvoiceModal";
import {ActionIconButton} from "../../../../common/actionButton/ActionIconButton";

interface Props {
  extractions: ApiExtraction[]
  extractionModalRef: RefObject<any>
  createInvoiceFromExtraction: (extractionId: string) => void
  remapInvoiceFromExtraction: (extractionId: string) => void
  unlinkExtractionDocument: (extractionId: string) => void
  deleteExtraction: (extractionId: string) => void
}

export const FileExtractionsTab = ({
                                     extractions,
                                     extractionModalRef,
                                     createInvoiceFromExtraction,
                                     remapInvoiceFromExtraction,
                                     unlinkExtractionDocument,
                                     deleteExtraction
                                   }: Props) => {
  const invoiceModalRef = useRef<any>();

  return <>

    <Stack dir="row" align="flex-start">
      <Space w={20}/>

      <Table style={{width: "1%", minWidth: "700px"}}
             verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}
             className="datatable">
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Status</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Document Type</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Linked Object</Table.Th>
            <Table.Th style={{textAlign: "left"}}>Description</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Action</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {extractions.map((extraction, idx) => (
            <Table.Tr key={idx}>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => extractionModalRef.current?.openModal(extraction.fileId, extraction.id)}>
                {idx + 1}
              </Table.Td>
              <Table.Td onClick={() => extractionModalRef.current?.openModal(extraction.fileId, extraction.id)}>
                <StatusBadge status={extraction.status}/>
              </Table.Td>
              <Table.Td style={{textAlign: "right"}}
                        onClick={() => extractionModalRef.current?.openModal(extraction.fileId, extraction.id)}>
                {extraction.documentType}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}}
                        onClick={() => extractionModalRef.current?.openModal(extraction.fileId, extraction.id)}>
                <DocumentLink type={extraction.documentType}
                              id={extraction.documentId}
                              onClick={() => invoiceModalRef.current?.openModal(extraction.documentId)}/>
              </Table.Td>
              <Table.Td onClick={() => extractionModalRef.current?.openModal(extraction.fileId, extraction.id)}>
                {extraction.description}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => extractionModalRef.current?.openModal(extraction.fileId, extraction.id)}>
                <ActionIconButton
                  label={<IconDotsVertical size='12px' stroke={1.5}/>}
                  size="sm"
                  options={[
                    {
                      label: 'Create document (incoming invoice)',
                      action: () => createInvoiceFromExtraction(extraction.id),
                      leftSection: <IconLink size="1.5rem" stroke={1.5}/>,
                      disabled: !!extraction.documentId
                    },
                    {
                      label: 'Remap document',
                      action: () => remapInvoiceFromExtraction(extraction.id),
                      leftSection: <IconArrowForwardUpDouble size="1.5rem" stroke={1.5}/>,
                      disabled: !extraction.documentId
                    },
                    {
                      label: 'Unlink document',
                      action: () => unlinkExtractionDocument(extraction.id),
                      leftSection: <IconUnlink size="1.5rem" stroke={1.5}/>,
                      disabled: !extraction.documentId
                    },
                    'DIVIDER',
                    {
                      label: 'Delete',
                      action: () => deleteExtraction(extraction.id),
                      leftSection: <IconTrashX size="1.5rem" stroke={1.5} color={'red'}/>,
                      disabled: !!extraction.documentId
                    }
                  ]}
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
    <IncomingInvoiceModal ref={invoiceModalRef} onSuccess={() => null}/>
  </>;
}