import {Button, Flex, Grid, LoadingOverlay, Modal, Space, Text} from "@mantine/core";
import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {notifications} from "@mantine/notifications";
import {ApiClient, ApiContact, ApiItem, ApiTax, ApiUnit, BookingStatus} from "../../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../../hooks/useOrganisationId";
import {IconBook, IconCircleX, IconDeviceFloppy, IconExclamationCircle, IconTrashX} from "@tabler/icons-react";
import {
  IncomingInvoice,
  IncomingInvoiceLineItem,
  IncomingInvoiceTaxItem,
  mapApiInvoice,
  mapToUpsertRequest
} from "./types";
import {TextInput} from "../../../../../common/TextInput";
import {ContactSelect} from "../../../../../common/select/ContactSelect";
import {DataSheet} from "../../../../../common/datasheet/DataSheet";
import {Option} from "../../../../../common/datasheet/DataSheetSelect";
import {createIdMap, roundToX} from "../../../../../utils/objectUtils";
import {DateInput} from "../../../../../common/DateInput";
import {formatNumber} from "../../../../../utils/formatUtils";
import {StatusBadge} from "../../../../../common/StatusBadge";
import {ActionButton} from "../../../../../common/actionButton/ActionButton";
import {ItemModal} from "../../items/ItemModal";
import {TaxModal} from "../../taxes/TaxModal";
import {notifyError, notifySavedChanges} from "../../../../../utils/notificationUtils";

interface Props {
  onSuccess: () => void
}

interface Totals {
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export const IncomingInvoiceModal = React.forwardRef(({onSuccess}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [invoiceId, setInvoiceId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [invoice, setInvoice] = useState<IncomingInvoice>(mapApiInvoice(undefined));
  const [contacts, setContacts] = useState<ApiContact[]>([]);
  const [items, setItems] = useState<ApiItem[]>([]);
  const [taxes, setTaxes] = useState<ApiTax[]>([]);
  const [units, setUnits] = useState<ApiUnit[]>([]);
  const [itemsById, setItemsById] = useState<Record<string, ApiItem>>({});
  const [unitsById, setUnitsById] = useState<Record<string, ApiUnit>>({});

  const modalRefs = {
    item: useRef<any>(),
    tax: useRef<any>(),
  };

  const fetchEntities = useCallback(() => {
    if (organisationId) {
      setLoading(true);
      return Promise.all([
        invoiceId
          ? ApiClient.getIncomingInvoice(organisationId, invoiceId).then(resp => resp.data)
          : Promise.resolve(undefined),
        ApiClient.getTaxes(organisationId).then(resp => resp.data),
        ApiClient.getItems(organisationId).then(resp => resp.data),
        ApiClient.getContacts(organisationId).then(resp => resp.data),
        ApiClient.getUnits(organisationId).then(resp => resp.data)
      ]).then(([invoice, taxes, items, contacts, units]) => {
        setInvoice(mapApiInvoice(invoice))
        setTaxes(taxes);
        setItems(items);
        setContacts(contacts);
        setUnits(units);
        setItemsById(createIdMap(items));
        setUnitsById(createIdMap(units));
      }).catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId, invoiceId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const openModal = useCallback((invoiceId?: string) => {
    setInvoiceId(invoiceId);
    open();
  }, [open]);

  const closeModal = useCallback((success: boolean) => {
    if (success) {
      onSuccess();
    }
    setInvoiceId(undefined);
    setInvoice(mapApiInvoice(undefined));
    close();
  }, [close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (bookingStatus: BookingStatus) => {
    if (organisationId) {
      setLoading(true);
      const request = mapToUpsertRequest(invoice, bookingStatus);
      (invoice.id
          ? ApiClient.updateIncomingInvoice(organisationId, invoice.id, request)
          : ApiClient.createIncomingInvoice(organisationId, request)
      )
        .then(() => closeModal(true))
        .then(notifySavedChanges)
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  }

  const [itemOptions, setItemOptions] = useState<Option[]>([]);
  const [taxOptions, setTaxOptions] = useState<Option[]>([]);

  useEffect(() => {
    setItemOptions(items.filter(i => i.stocked).map(i => ({value: i.id, label: i.name} as Option)));
    setTaxOptions(taxes.map(t => ({value: t.id, label: `${t.rate * 100}%`} as Option)));
  }, [items, units, taxes])


  const [totals, setTotals] = useState([{
    name: 'Total',
    netAmount: null,
    taxAmount: null,
    grossAmount: null
  } as Totals]);
  useEffect(() => {
    setTotals([{
      name: 'Total',
      netAmount: invoice?.netAmount,
      taxAmount: invoice?.taxAmount,
      grossAmount: invoice?.grossAmount,
    }]);
  }, [invoice]);

  return <>
    <LoadingOverlay visible={loading}/>
    <Modal opened={opened}
           onClose={() => closeModal(false)}
           title={`Incoming Invoice [ID: ${invoiceId}]`}
           closeOnClickOutside={false}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size="auto"
    >
      <Flex align="left" direction="column" gap="xs">
        <Flex direction={"row"} gap="md" style={{width: '100%'}}>
          <Grid style={{width: '23rem'}} gutter="sm">
            <Grid.Col span={4}>
              <Text size="sm">Status: </Text>
            </Grid.Col>

            <Grid.Col span={8}>
              <StatusBadge status={invoice.bookingStatus ?? 'CREATING'}/>
            </Grid.Col>

            <Grid.Col span={4}>
              <Text size="sm">Supplier: </Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <ContactSelect inputLabel="Customer"
                             contactId={invoice.supplierId ?? undefined}
                             contacts={contacts}
                             onChange={contactId => setInvoice({...invoice, supplierId: contactId ?? null})}
                             onNewCreated={fetchEntities}/>
            </Grid.Col>

            <Grid.Col span={4}>
              <Text size="sm">Invoice number: </Text>
            </Grid.Col>

            <Grid.Col span={8}>
              <TextInput label="Invoice Number"
                         value={invoice.invoiceNumber}
                         onChange={value => setInvoice({...invoice, invoiceNumber: value})}/>
            </Grid.Col>

          </Grid>

          <Grid style={{width: '14rem'}} gutter="sm">
            <Grid.Col span={5}>
              <Text size="sm">Issue date: </Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <DateInput value={invoice.issueDate} onChange={date => setInvoice({...invoice, issueDate: date})}/>
            </Grid.Col>

            <Grid.Col span={5}>
              <Text size="sm">Due date: </Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <DateInput value={invoice.dueDate} onChange={date => setInvoice({...invoice, dueDate: date})}/>
            </Grid.Col>

            <Grid.Col span={5}>
              <Text size="sm">Booking date: </Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <DateInput value={invoice.bookingDate}
                         onChange={date => setInvoice({...invoice, bookingDate: date})}/>
            </Grid.Col>

          </Grid>
        </Flex>

        <Space h="1rem"/>

        <DataSheet
          caption="Line items"
          noWrap={true}
          columns={[
            {name: 'text', displayName: 'Text', type: 'TEXT', align: 'left'},
            {
              name: 'itemId',
              displayName: 'Item',
              type: 'SELECT',
              align: 'center',
              selectOptions: itemOptions,
              modalRef: modalRefs.item,
            },
            {
              name: 'unit', displayName: 'Unit', type: 'DERIVED', align: 'center',
              deriveFunction: (row) => {
                const item = itemsById[invoice.lineItems[row].itemId ?? ''];
                const unit = unitsById[item?.unitId ?? ''];
                return unit?.shortName ?? '';
              }
            },
            {
              name: 'unitPrice', displayName: 'Unit Price', type: 'DERIVED', align: 'right',
              deriveFunction: (row) => {
                const netAmount = invoice.lineItems[row].netAmount;
                const quantity = invoice.lineItems[row].quantity;
                return (netAmount && netAmount !== 0 && quantity && quantity !== 0)
                  ? formatNumber(roundToX(netAmount / quantity, 4))
                  : '';
              }
            },
            {name: 'quantity', displayName: 'Quantity', type: 'NUMBER', align: 'right'},
            {
              name: 'taxId',
              displayName: 'Tax',
              type: 'SELECT',
              align: 'right',
              selectOptions: taxOptions,
              modalRef: modalRefs.tax,
            },
            {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', align: 'right'},
            {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', align: 'right'},
            {name: 'grossAmount', displayName: 'Gross Amount', type: 'AMOUNT', align: 'right'},
          ]}
          rows={invoice.lineItems}
          updateRows={(rows) => {
            const newLineItems = rows.map(row => {
              const lineItem = {} as IncomingInvoiceLineItem;
              Object.keys(row).forEach(col => lineItem[col] = row[col])
              return lineItem;
            });
            setInvoice({...invoice, lineItems: newLineItems});
          }}
          rowValidityCheckers={[]}/>

        <Space h="5px"/>

        <Flex direction="column" justify="right">
          <DataSheet
            caption="VAT items"
            noWrap={true}
            columns={[
              {name: 'taxId', displayName: 'Tax', type: 'SELECT', align: 'right', selectOptions: taxOptions},
              {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', align: 'right'},
              {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', align: 'right'},
              {name: 'grossAmount', displayName: 'Gross Amount', type: 'AMOUNT', align: 'right'},
            ]}
            rows={invoice.taxItems}
            updateRows={(rows) => {
              const newTaxItems = rows.map(row => {
                const taxItem = {} as IncomingInvoiceTaxItem;
                Object.keys(row).forEach(col => taxItem[col] = row[col])
                return taxItem;
              });
              setInvoice({...invoice, taxItems: newTaxItems});
            }}
            rowValidityCheckers={[]}/>
        </Flex>


        <DataSheet
          caption={"Totals"}
          noWrap={true}
          columns={[
            {name: 'name', displayName: 'Name', type: 'TEXT', align: 'right', readOnly: true},
            {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', align: 'right'},
            {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', align: 'right'},
            {name: 'grossAmount', displayName: 'Gross Amount', type: 'AMOUNT', align: 'right'},
          ]}
          rows={totals}
          updateRows={(rows) => {
            setInvoice({
              ...invoice,
              netAmount: Number(rows[0].netAmount),
              taxAmount: Number(rows[0].taxAmount),
              grossAmount: Number(rows[0].grossAmount)
            });
          }}
          rowValidityCheckers={[]}
        />

        <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
          <ActionButton
            defaultAction={
              {
                label: "Save as Draft",
                action: () => handleSave('DRAFT'),
                leftSection: <IconDeviceFloppy size="1.5rem" stroke={1.5}/>,
                disabled: invoice?.bookingStatus !== 'DRAFT'
              }
            }
            options={[
              {
                label: <>Save and book as <StatusBadge status="PARTIAL"/></>,
                action: () => handleSave('PARTIAL'),
                leftSection: <IconBook size="1.5rem" stroke={1.5}/>,
                disabled: invoice?.bookingStatus === 'PARTIAL'
              },
              {
                label: <>Save and book as <StatusBadge status="BOOKED"/></>,
                action: () => handleSave('BOOKED'),
                leftSection: <IconBook size="1.5rem" stroke={1.5}/>,
                disabled: invoice?.bookingStatus === 'BOOKED'
              },
              'DIVIDER',
              {
                label: 'Delete',
                action: () => null,
                leftSection: <IconTrashX size="1.5rem" stroke={1.5} color={'red'}/>,
                disabled: invoice?.bookingStatus === undefined
              },
            ]}
          />
          <Button variant="outline" rightSection={<IconCircleX/>} onClick={() => closeModal(false)} disabled={loading}>
            Cancel
          </Button>
        </Flex>
      </Flex>
      <ItemModal ref={modalRefs.item} onSuccess={fetchEntities}/>
      <TaxModal ref={modalRefs.tax} onSuccess={fetchEntities}/>
    </Modal>
  </>;
});