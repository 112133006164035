import {Button, Flex, Grid, Modal, Switch, Text} from "@mantine/core";
import React, {useCallback, useImperativeHandle, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {notifications} from "@mantine/notifications";
import {ApiClient, ApiItem, ApiItemCategory, UpsertItemCategoryRequest} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {IconCheck, IconCircleX, IconExclamationCircle} from "@tabler/icons-react";
import {TextInput} from "../../../../common/TextInput";

interface Props {
  onSuccess?: (apiItemCategory: ApiItemCategory) => void
  onClose?: () => void
}

export interface Category {
  id?: string
  name: string
  active: boolean
}


const mapApiCategory = (apiCategory?: ApiItemCategory) => {
  return {
    id: apiCategory?.id,
    name: apiCategory ? apiCategory.name : '',
    active: apiCategory ? apiCategory.active : true,
  } as Category;
}

export const CategoryModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [category, setCategory] = useState<Category>(mapApiCategory(undefined));

  const openModal = useCallback((apiItem?: ApiItem) => {
    setCategory(mapApiCategory(apiItem));
    open()
  }, [open]);

  const closeModal = useCallback(() => {
    setCategory(mapApiCategory(undefined))
    onClose?.();
    close();
  }, [setCategory, close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = () => {
    setLoading(true);
    const request = {
      name: category.name,
      active: category.active,
    } as UpsertItemCategoryRequest;
    (category.id
        ? ApiClient.updateItemCategory(organisationId ?? '', category.id, request)
        : ApiClient.createItemCategory(organisationId ?? '', request)
    )
      .then((resp) => {
        onSuccess?.(resp.data);
      })
      .then(() => closeModal())
      .catch(err => notifications.show({
        message: "Failed to save tax: " + err.message, color: "red", icon: <IconExclamationCircle/>
      }))
      .finally(() => setLoading(false));
  }

  return <>
    <Modal opened={opened}
           id="category_modal"
           onClose={closeModal}
           title={`Category [ID: ${category.id}]`}
           closeOnClickOutside={false}
           returnFocus={true}
           closeOnEscape={false}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size={"400px"}
    >
      <Flex align="center" direction="column" gap="sm">
        <Grid style={{width: '20rem'}} gutter="sm" align="center">
          <Grid.Col span={2}>
            <Text size="sm">Name: </Text>
          </Grid.Col>
          <Grid.Col span={10}>
            <TextInput value={category.name}
                       disabled={loading}
                       onChange={value => setCategory({...category, name: value ?? ''})}/>
          </Grid.Col>
        </Grid>

        <Flex direction="row" justify="right" style={{width: "100%", paddingTop: '30px'}}>
          <Switch size={"sm"}
                  labelPosition="left"
                  label="Active"
                  style={{paddingTop: '5px'}}
                  checked={category.active}
                  onChange={e => setCategory({...category, active: e.target.checked})}
          />
        </Flex>

        <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
          <Button onClick={handleSave} rightSection={<IconCheck/>} loading={loading}>
            Save
          </Button>
          <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal} disabled={loading}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  </>;
});