import {Currency} from "../../../../../utils/currency";
import {
  ApiIncomingInvoice,
  ApiIncomingInvoiceLineItem,
  ApiIncomingInvoiceTaxItem,
  ApiUpsertIncomingInvoiceLineItemRequest,
  ApiUpsertIncomingInvoiceRequest,
  ApiUpsertIncomingInvoiceTaxItemRequest,
  BookingStatus
} from "../../../../../utils/http/apiClient";
import {mapDate} from "../../../../../utils/date/dateUtils";
import {nonEmptyObjects} from "../../../../../utils/objectUtils";

export interface IncomingInvoice {
  id?: string
  supplierId: string | null
  invoiceNumber: string | null
  currency: Currency | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  issueDate: Date | null
  dueDate: Date | null
  deliveryDate: Date | null
  bookingDate: Date | null
  bookingStatus: BookingStatus | null
  lineItems: IncomingInvoiceLineItem[]
  taxItems: IncomingInvoiceTaxItem[]
}

export interface IncomingInvoiceLineItem {
  id: string
  organisationId: string
  incomingInvoiceId: string
  itemId: string | null
  taxId: string | null
  text: string | null
  unitPrice: number | null
  quantity: number | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  excluded: string | null

  [key: string]: any
}

export interface IncomingInvoiceTaxItem {
  id: string
  organisationId: string
  incomingInvoiceId: string
  itemId: string | null
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}


export const mapApiInvoice = (apiInvoice?: ApiIncomingInvoice) => {
  return {
    id: apiInvoice?.id,
    supplierId: apiInvoice?.supplierId,
    invoiceNumber: apiInvoice?.invoiceNumber,
    currency: apiInvoice?.currency,
    netAmount: apiInvoice?.netAmount,
    taxAmount: apiInvoice?.taxAmount,
    grossAmount: apiInvoice?.grossAmount,
    issueDate: mapDate(apiInvoice?.issueDate),
    dueDate: mapDate(apiInvoice?.dueDate),
    deliveryDate: mapDate(apiInvoice?.deliveryDate),
    bookingDate: mapDate(apiInvoice?.bookingDate),
    bookingStatus: apiInvoice?.bookingStatus,
    lineItems: apiInvoice
      ? apiInvoice?.lineItems.map(mapApiInvoiceLineItem)
      : [...Array(3)].map(_ => mapApiInvoiceLineItem(undefined)),
    taxItems: apiInvoice
      ? apiInvoice?.taxItems.map(mapApiInvoiceLineTaxItem)
      : [...Array(3)].map(_ => mapApiInvoiceLineTaxItem(undefined)),
  } as IncomingInvoice;
}

const mapApiInvoiceLineItem = (lineItem?: ApiIncomingInvoiceLineItem) => {
  return {
    id: lineItem?.id ?? crypto.randomUUID(),
    organisationId: lineItem?.organisationId,
    incomingInvoiceId: lineItem?.incomingInvoiceId,
    itemId: lineItem?.itemId,
    taxId: lineItem?.taxId,
    text: lineItem?.text,
    unitPrice: lineItem?.unitPrice,
    quantity: lineItem?.quantity,
    netAmount: lineItem?.netAmount,
    taxAmount: lineItem?.taxAmount,
    grossAmount: lineItem?.grossAmount,
  } as IncomingInvoiceLineItem;
}

const mapApiInvoiceLineTaxItem = (taxItem?: ApiIncomingInvoiceTaxItem) => {
  return {
    id: taxItem?.id ?? crypto.randomUUID(),
    organisationId: taxItem?.organisationId,
    incomingInvoiceId: taxItem?.incomingInvoiceId,
    taxId: taxItem?.taxId,
    netAmount: taxItem?.netAmount,
    taxAmount: taxItem?.taxAmount,
    grossAmount: taxItem?.grossAmount,
  } as IncomingInvoiceTaxItem;
}


export const mapToUpsertRequest = (invoice: IncomingInvoice, bookingStatus: BookingStatus) => {
  return {
    supplierId: invoice.supplierId,
    invoiceNumber: invoice.invoiceNumber,
    currency: invoice.currency,
    netAmount: invoice?.netAmount,
    taxAmount: invoice?.taxAmount,
    grossAmount: invoice?.grossAmount,
    issueDate: invoice.issueDate,
    dueDate: invoice.dueDate,
    deliveryDate: invoice.deliveryDate,
    lineItems: nonEmptyObjects(invoice.lineItems.map(mapLineItemUpsertRequest)),
    taxItems: nonEmptyObjects(invoice.taxItems.map(mapTaxItemUpsertRequest)),
    bookingDate: invoice.bookingDate,
    bookingStatus: bookingStatus,
  } as ApiUpsertIncomingInvoiceRequest;
}

export const mapLineItemUpsertRequest = (lineItem: IncomingInvoiceLineItem) => {
  return {
    itemId: lineItem.itemId,
    taxId: lineItem.taxId,
    text: lineItem.text,
    quantity: lineItem.quantity,
    netAmount: lineItem.netAmount,
    taxAmount: lineItem.taxAmount,
    grossAmount: lineItem.grossAmount,
  } as ApiUpsertIncomingInvoiceLineItemRequest;
}

export const mapTaxItemUpsertRequest = (taxItem: ApiUpsertIncomingInvoiceTaxItemRequest) => {
  return {
    taxId: taxItem.taxId,
    netAmount: taxItem.netAmount,
    taxAmount: taxItem.taxAmount,
    grossAmount: taxItem.grossAmount,
  } as ApiUpsertIncomingInvoiceTaxItemRequest;
}