import {
  ApiRegisterReport,
  ApiRegisterReportLineItem,
  ApiRegisterReportTaxItem, ApiUpsertIncomingInvoiceTaxItemRequest,
  ApiUpsertRegisterReportLineItemRequest,
  ApiUpsertRegisterReportRequest, ApiUpsertRegisterReportTaxItemRequest, BookingStatus
} from "../../../../../utils/http/apiClient";
import {mapDate} from "../../../../../utils/date/dateUtils";
import {nonEmptyObjects} from "../../../../../utils/objectUtils";

export interface RegisterReport {
  id?: string
  registerId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  date: Date | null
  bookingDate: Date | null
  bookingStatus: BookingStatus | null
  lineItems: RegisterReportLineItem[]
  taxItems: RegisterReportTaxItem[]
}

export interface RegisterReportLineItem {
  id: string
  organisationId: string
  registerReportId: string
  itemId: string | null
  taxId: string | null
  text: string | null
  quantity: number | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export interface RegisterReportTaxItem {
  id: string
  organisationId: string
  registerReportId: string
  itemId: string | null
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}


export const mapToReport = (apiReport?: ApiRegisterReport) => {
  return {
    id: apiReport?.id,
    registerId: apiReport?.registerId,
    netAmount: apiReport?.netAmount,
    taxAmount: apiReport?.taxAmount,
    grossAmount: apiReport?.grossAmount,
    date: mapDate(apiReport?.date),
    bookingDate: mapDate(apiReport?.bookingDate),
    bookingStatus: apiReport?.bookingStatus,
    lineItems: apiReport
      ? apiReport?.lineItems.map(mapApiReportLineItem)
      : [...Array(3)].map(_ => mapApiReportLineItem(undefined)),
    taxItems: apiReport
      ? apiReport?.taxItems.map(mapApiRegisterReportTaxItem)
      : [...Array(3)].map(_ => mapApiRegisterReportTaxItem(undefined))
  } as RegisterReport;
}

const mapApiReportLineItem = (lineItem?: ApiRegisterReportLineItem) => {
  return {
    id: lineItem?.id ?? crypto.randomUUID(),
    organisationId: lineItem?.organisationId,
    registerReportId: lineItem?.registerReportId,
    itemId: lineItem?.itemId,
    taxId: lineItem?.taxId,
    text: lineItem?.text,
    quantity: lineItem?.quantity,
    netAmount: lineItem?.netAmount,
    taxAmount: lineItem?.taxAmount,
    grossAmount: lineItem?.netAmount,

  } as RegisterReportLineItem;
}

const mapApiRegisterReportTaxItem = (taxItem?: ApiRegisterReportTaxItem) => {
  return {
    id: taxItem?.id ?? crypto.randomUUID(),
    organisationId: taxItem?.organisationId,
    registerReportId: taxItem?.registerReportId,
    taxId: taxItem?.taxId,
    netAmount: taxItem?.netAmount,
    taxAmount: taxItem?.taxAmount,
    grossAmount: taxItem?.grossAmount,
  } as RegisterReportTaxItem;
}

export const mapToUpsertRequest = (report: RegisterReport, bookingStatus: BookingStatus) => {
  return {
    registerId: report.registerId,
    netAmount: report.netAmount,
    taxAmount: report.taxAmount,
    grossAmount: report.grossAmount,
    date: report.date,
    bookingDate: report.bookingDate,
    bookingStatus: bookingStatus,
    lineItems: nonEmptyObjects(report.lineItems.map(mapLineItemUpsertRequest)),
    taxItems: nonEmptyObjects(report.taxItems.map(mapTaxItemUpsertRequest)),
  } as ApiUpsertRegisterReportRequest;
}

export const mapLineItemUpsertRequest = (lineItem: ApiUpsertRegisterReportLineItemRequest) => {
  return {
    itemId: lineItem.itemId,
    taxId: lineItem.taxId,
    text: lineItem.text,
    quantity: lineItem.quantity,
    netAmount: lineItem?.netAmount,
    taxAmount: lineItem?.taxAmount,
    grossAmount: lineItem?.grossAmount,
  } as ApiUpsertRegisterReportLineItemRequest;
}

export const mapTaxItemUpsertRequest = (taxItem: ApiUpsertRegisterReportTaxItemRequest) => {
  return {
    taxId: taxItem.taxId,
    netAmount: taxItem.netAmount,
    taxAmount: taxItem.taxAmount,
    grossAmount: taxItem.grossAmount,
  } as ApiUpsertRegisterReportTaxItemRequest;
}