import {
  ApiClient,
  ApiContact,
  ApiExtraction,
  ApiIncomingInvoice,
  ApiIncomingInvoiceFindSimilarRequest
} from "../../../../../utils/http/apiClient";
import React, {useEffect, useRef, useState} from "react";
import {Anchor, HoverCard, Loader, Table, Text} from "@mantine/core";
import {notifyError} from "../../../../../utils/notificationUtils";
import {DocumentLink} from "../../../../../common/DocumentLink";
import {formatDate} from "../../../../../utils/date/dateUtils";
import {createIdMap} from "../../../../../utils/objectUtils";
import {formatAmount} from "../../../../../utils/formatUtils";
import {cleanNumber} from "./extractionsUtils";
import {IncomingInvoiceModal} from "../../incoming/invoices/IncomingInvoiceModal";

interface Props {
  organisationId: string | undefined,
  apiExtraction: ApiExtraction | undefined,
}

export const SimilarInvoices = ({organisationId, apiExtraction}: Props) => {
  const invoiceModalRef = useRef<any>();

  const [loading, setLoading] = useState(false);
  const [contactsById, setContactsById] = useState<Record<string, ApiContact>>({});

  const [supplierId, setSupplierId] = useState<string | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string | null>(null);
  const [issueDate, setIssueDate] = useState<string | null>(null);
  const [grossAmount, setGrossAmount] = useState<number | null>(null);

  const [similarInvoices, setSimilarInvoices] = useState<ApiIncomingInvoice[]>([]);

  useEffect(() => {
    if (supplierId !== apiExtraction?.body.supplierId) {
      setSupplierId(apiExtraction?.body.supplierId ?? null);
    }
    if (invoiceNumber !== apiExtraction?.body.invoiceNumber) {
      setInvoiceNumber(apiExtraction?.body.invoiceNumber ?? null);
    }
    if (issueDate !== apiExtraction?.body.issueDate) {
      setIssueDate(apiExtraction?.body.issueDate ?? null);
    }
    if (issueDate !== apiExtraction?.body.grossAmount) {
      const grossAmount = Number(cleanNumber(apiExtraction?.body.grossAmount ?? ''));
      setGrossAmount(grossAmount);
    }
  }, [apiExtraction, supplierId, invoiceNumber, issueDate, grossAmount]);

  useEffect(() => {
    if (supplierId && organisationId && supplierId) {
      setLoading(true);

      const contactsRequest = ApiClient.getContacts(organisationId);
      const findSimilarRequest = ApiClient.findSimilarIncomingInvoices(
        organisationId,
        {
          supplierId, issueDate, invoiceNumber
        } as ApiIncomingInvoiceFindSimilarRequest);

      Promise.all([
        contactsRequest,
        findSimilarRequest
      ])
        .then(([contactsResp, findSimilarResp]) => {
          setContactsById(createIdMap(contactsResp.data));
          setSimilarInvoices(findSimilarResp.data.filter(inv => inv.id !== apiExtraction?.documentId));
        })
        .catch(notifyError)
        .finally(() => setLoading(false));
    }

  }, [supplierId, invoiceNumber, issueDate]);

  return <>
    {loading
      ? <>
        <Loader size="xs"/>
      </>
      : similarInvoices.length > 0
        ? <>
          <HoverCard shadow="xl" zIndex={200}>
            <HoverCard.Target>
              <Anchor>
                <Text size={"sm"}>Found {similarInvoices.length} similar invoice(s)</Text>
              </Anchor>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Table verticalSpacing={1}
                     withTableBorder
                     withColumnBorders
                     highlightOnHover={true}
                     className={'datatable'}>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Invoice</Table.Th>
                    <Table.Th>Supplier</Table.Th>
                    <Table.Th>Invoice Number</Table.Th>
                    <Table.Th>Issue Date</Table.Th>
                    <Table.Th>Total Amount</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {similarInvoices.map((inv, idx) => (
                    <Table.Tr key={idx}>
                      <Table.Td>
                        <DocumentLink type="INCOMING_INVOICE" id={inv.id}
                                      onClick={() => invoiceModalRef.current?.openModal(inv.id)}/>
                      </Table.Td>
                      <Table.Td>
                        {contactsById[inv.supplierId ?? '']?.name}
                      </Table.Td>
                      <Table.Td className={invoiceNumber === inv.invoiceNumber ? 'match-positive' : 'match-negative'}
                                style={{textAlign: 'center'}}>
                        {inv.invoiceNumber}
                      </Table.Td>
                      <Table.Td className={issueDate === inv.issueDate ? 'match-positive' : 'match-negative'}
                                style={{textAlign: 'center'}}>
                        {formatDate(inv.issueDate)}
                      </Table.Td>
                      <Table.Td className={grossAmount === inv.grossAmount ? 'match-positive' : 'match-negative'}
                                style={{textAlign: 'right'}}>
                        {formatAmount(inv.grossAmount ?? NaN)}
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </HoverCard.Dropdown>
          </HoverCard>

          <IncomingInvoiceModal ref={invoiceModalRef} onSuccess={() => null}/>
        </>
        : <></>}
  </>
}